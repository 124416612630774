#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

html,
body {
  position: relative;
  height: 100%;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper {
  width: 300px;
  height: 500px;
}

.swiper-slide {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-slide:nth-child(1n) {
  background-color: #f0d36c
}

.swiper-slide:nth-child(2n) {
  background-color: #6cb0f0
}

.swiper-slide:nth-child(3n) {
  background-color:#87CEEB
}

.swiper-slide:nth-child(4n) {
  background-color: #fff
}

.swiper-slide:nth-child(5n) {
  background-color:#fff
}

.swiper-slide:nth-child(6n) {
  background-color: #fff
}

.swiper-slide:nth-child(7n) {
  background-color: #fff
}

.swiper-slide:nth-child(8n) {
  background-color:#fff
}

.swiper-slide:nth-child(9n) {
  background-color: #fff
}

.swiper-slide:nth-child(10n) {
  background-color: #fff;
}

.mySwiper{
  /* align-items: center !important; */
}